const fetchData = {

    async structureTosendData(url, dataForm, csrftolkien, method = "POST") {
      
        //Headers Structure
        let response = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": csrftolkien,
            },
            body: JSON.stringify(dataForm),
        });

        //Valid Status
        let validStatus = [200, 201];

        if (!validStatus.includes(response.status)) {
            throw (
                response.error ||
                new Error(`Request failed with status ${response.status}`)
            );
        }

        let data = await response.json();

        return data;
        
    },
    async getData(url) {

        //Get Http Response
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        });

        //Verify Status
        if (response.status !== 200) {
            throw (
                response.error ||
                new Error(
                    `Erro ao processar requisição com o ${response.status}`
                )
            );
        }

        //Get Data how json
        let data = await response.json();

        return data;
         
    },
    async toBase64(file) {

        let file64 = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
  
        return file64;
    },
    
}

export default fetchData;